import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { motion, AnimatePresence } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaMoon, FaSun, FaSearch, FaPlus, FaHistory } from 'react-icons/fa';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

const urgencyLevels = {
  "Low": 1,
  "Normal": 2,
  "Urgent": 3,
  "Very Urgent": 4,
  "Critical": 5
};

const categories = [
  "Work", "Sexy Time", "Health", "Money", "Education", "Home", "Shopping", "Other"
];

const TaskDashboard = () => {
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [newTask, setNewTask] = useState({ 
    task: '', 
    description: '', 
    urgency: 'Normal', 
    due_date: '',
    category: 'Other'
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [darkMode, setDarkMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddTask, setShowAddTask] = useState(false);
  const [activeTab, setActiveTab] = useState('active');

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    try {
      const { data: activeTasks, error: activeError } = await supabase
        .from('tasks')
        .select('*')
        .eq('completed', false)
        .order('created_at', { ascending: false });

      const { data: completedTasksData, error: completedError } = await supabase
        .from('tasks')
        .select('*')
        .eq('completed', true)
        .order('created_at', { ascending: false });
      
      if (activeError || completedError) throw activeError || completedError;
      setTasks(activeTasks || []);
      setCompletedTasks(completedTasksData || []);
    } catch (err) {
      setError('Failed to fetch tasks');
      toast.error('Failed to fetch tasks');
    } finally {
      setIsLoading(false);
    }
  };

  const addTask = async (e) => {
    e.preventDefault();
    if (newTask.task.trim() !== '' && newTask.due_date) {
      try {
        const { error } = await supabase
          .from('tasks')
          .insert([{ 
            ...newTask,
            status: 'Not Started',
            completed: false,
            pending: false
          }]);

        if (error) throw error;
        setNewTask({ task: '', description: '', urgency: 'Normal', due_date: '', category: 'Other' });
        fetchTasks();
        toast.success('Task added successfully!');
        setShowAddTask(false);
      } catch (err) {
        setError('Failed to add task');
        toast.error('Failed to add task');
      }
    } else {
      toast.error('Please fill in all required fields');
    }
  };

  const toggleTaskStatus = async (id, field) => {
    try {
      const taskToUpdate = tasks.find(task => task.id === id) || completedTasks.find(task => task.id === id);
      const { error } = await supabase
        .from('tasks')
        .update({ 
          [field]: !taskToUpdate[field],
          status: !taskToUpdate[field] 
            ? (field === 'completed' ? 'Completed' : 'In Progress') 
            : (taskToUpdate.completed ? 'Completed' : taskToUpdate.pending ? 'In Progress' : 'Not Started')
        })
        .eq('id', id);

      if (error) throw error;
      fetchTasks();
      toast.success(`Task ${field === 'completed' ? 'completed' : 'updated'} successfully!`);
    } catch (err) {
      setError('Failed to update task');
      toast.error('Failed to update task');
    }
  };

  const removeTask = async (id) => {
    try {
      const { error } = await supabase
        .from('tasks')
        .delete()
        .eq('id', id);

      if (error) throw error;
      fetchTasks();
      toast.success('Task removed successfully!');
    } catch (err) {
      setError('Failed to remove task');
      toast.error('Failed to remove task');
    }
  };

  const reactivateTask = async (id) => {
    try {
      const { error } = await supabase
        .from('tasks')
        .update({ completed: false, status: 'Not Started' })
        .eq('id', id);

      if (error) throw error;
      fetchTasks();
      toast.success('Task reactivated successfully!');
    } catch (err) {
      setError('Failed to reactivate task');
      toast.error('Failed to reactivate task');
    }
  };

  const filteredTasks = (activeTab === 'active' ? tasks : completedTasks).filter(task =>
    task.task.toLowerCase().includes(searchTerm.toLowerCase()) ||
    task.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const chartData = filteredTasks.map((task) => ({
    name: task.task,
    urgency: task.completed ? 0 : urgencyLevels[task.urgency],
    category: task.category
  }));

  if (isLoading) return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  );
  
  if (error) return <div className="text-red-500 text-center">{error}</div>;

  return (
    <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gradient-to-r from-purple-400 via-pink-500 to-red-500'}`}>
      <ToastContainer position="top-right" autoClose={3000} theme={darkMode ? 'dark' : 'light'} />
      <div className="container mx-auto p-4">
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex justify-between items-center mb-8"
        >
          <motion.h1 
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            className="text-4xl font-bold"
          >
            ✨ Super Cool Task Dashboard ✨
          </motion.h1>
          <button 
            onClick={() => setDarkMode(!darkMode)}
            className="p-2 rounded-full bg-opacity-20 bg-white"
          >
            {darkMode ? <FaSun className="text-yellow-400" /> : <FaMoon className="text-purple-800" />}
          </button>
        </motion.div>

        <motion.div 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="mb-8"
        >
          <div className="flex items-center bg-white bg-opacity-20 rounded-full p-2">
            <FaSearch className="text-gray-500 mr-2" />
            <input
              type="text"
              placeholder="Search tasks..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="bg-transparent border-none focus:outline-none flex-grow"
            />
          </div>
        </motion.div>

        <div className="flex justify-between mb-8">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowAddTask(!showAddTask)}
            className="px-4 py-2 bg-green-500 text-white rounded-full flex items-center"
          >
            <FaPlus className="mr-2" /> {showAddTask ? 'Hide' : 'Add New Task'}
          </motion.button>

          <div className="flex space-x-2">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setActiveTab('active')}
              className={`px-4 py-2 rounded-full ${activeTab === 'active' ? 'bg-blue-500 text-white' : 'bg-white bg-opacity-20'}`}
            >
              Active Tasks
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setActiveTab('completed')}
              className={`px-4 py-2 rounded-full flex items-center ${activeTab === 'completed' ? 'bg-green-500 text-white' : 'bg-white bg-opacity-20'}`}
            >
              <FaHistory className="mr-2" /> Completed Tasks
            </motion.button>
          </div>
        </div>

        <AnimatePresence>
          {showAddTask && (
            <motion.div 
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              className="mb-8 bg-white bg-opacity-20 p-6 rounded-lg shadow-lg overflow-hidden"
            >
              <h2 className="text-2xl font-semibold mb-4">Add New Task</h2>
              <form onSubmit={addTask} className="space-y-4">
                <input
                  type="text"
                  value={newTask.task}
                  onChange={(e) => setNewTask({ ...newTask, task: e.target.value })}
                  placeholder="Enter task name"
                  className="w-full px-3 py-2 bg-white bg-opacity-50 rounded"
                  required
                />
                <textarea
                  value={newTask.description}
                  onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
                  placeholder="Enter task description"
                  className="w-full px-3 py-2 bg-white bg-opacity-50 rounded"
                />
                <div className="flex space-x-2">
                  <select
                    value={newTask.urgency}
                    onChange={(e) => setNewTask({ ...newTask, urgency: e.target.value })}
                    className="px-3 py-2 bg-white bg-opacity-50 rounded"
                  >
                    {Object.keys(urgencyLevels).map((level) => (
                      <option key={level} value={level}>{level}</option>
                    ))}
                  </select>
                  <select
                    value={newTask.category}
                    onChange={(e) => setNewTask({ ...newTask, category: e.target.value })}
                    className="px-3 py-2 bg-white bg-opacity-50 rounded"
                  >
                    {categories.map((category) => (
                      <option key={category} value={category}>{category}</option>
                    ))}
                  </select>
                  <input
                    type="date"
                    value={newTask.due_date}
                    onChange={(e) => setNewTask({ ...newTask, due_date: e.target.value })}
                    className="px-3 py-2 bg-white bg-opacity-50 rounded"
                    required
                  />
                </div>
                <motion.button 
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  type="submit" 
                  className="w-full px-4 py-2 bg-purple-500 text-white rounded-full"
                >
                  Add Task
                </motion.button>
              </form>
            </motion.div>
          )}
        </AnimatePresence>
        
        {filteredTasks.length > 0 && activeTab === 'active' && (
          <motion.div 
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            className="mb-8 bg-white bg-opacity-20 p-6 rounded-lg shadow-lg"
          >
            <h2 className="text-2xl font-semibold mb-4">Task Urgency Chart</h2>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={chartData} layout="vertical" margin={{ left: 50 }}>
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={150} />
                <Tooltip />
                <Legend />
                <Bar dataKey="urgency" fill="#8884d8">
                  {chartData.map((entry, index) => (
                    <motion.rect key={`cell-${index}`} initial={{ width: 0 }} animate={{ width: 'auto' }} transition={{ duration: 0.5 }} />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </motion.div>
        )}

        <AnimatePresence>
          {filteredTasks.map(task => (
            <motion.div 
              key={task.id} 
              initial={{ x: -300, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 300, opacity: 0 }}
              className="bg-white bg-opacity-20 p-6 rounded-lg shadow-lg mb-4"
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className={`text-xl font-semibold ${task.completed ? 'line-through opacity-50' : ''}`}>
                    {task.task}
                  </h3>
                  <p className="mt-2 opacity-75">{task.description}</p>
                  <div className="flex items-center mt-2 space-x-2">
                    <motion.span 
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      className={`px-2 py-1 rounded text-xs ${
                        task.urgency === 'Low' ? 'bg-green-500' :
                        task.urgency === 'Normal' ? 'bg-blue-500' :
                        task.urgency === 'Urgent' ? 'bg-yellow-500' :
                        task.urgency === 'Very Urgent' ? 'bg-orange-500' :
                        'bg-red-500'
                      }`}
                    >
                      {task.urgency}
                    </motion.span>
                    <motion.span 
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      className="px-2 py-1 bg-purple-500 rounded text-xs"
                    >
                      {task.category}
                    </motion.span>
                    <span className="text-sm opacity-75">
                      Due: {new Date(task.due_date).toLocaleDateString()}
                    </span>
                  </div>
                </div>
                <div className="flex space-x-2">
                  {activeTab === 'active' ? (
                    <>
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => toggleTaskStatus(task.id, 'pending')}
                        className={`p-2 rounded ${task.pending ? 'bg-yellow-500' : 'bg-opacity-50 bg-white'}`}
                      >
                        Pending
                      </motion.button>
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={() => toggleTaskStatus(task.id, 'completed')}
                        className={`p-2 rounded ${task.completed ? 'bg-green-500' : 'bg-opacity-50 bg-white'}`}
                      >
                        Complete
                      </motion.button>
                    </>
                  ) : (
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      onClick={() => reactivateTask(task.id)}
                      className="p-2 bg-blue-500 rounded"
                    >
                      Reactivate
                    </motion.button>
                  )}
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => removeTask(task.id)}
                    className="p-2 bg-red-500 rounded"
                  >
                    Remove
                  </motion.button>
                </div>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>

        {activeTab === 'completed' && completedTasks.length > 0 && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-8 bg-white bg-opacity-20 p-6 rounded-lg shadow-lg"
          >
            <h2 className="text-2xl font-semibold mb-4">Completed Tasks History</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Task</th>
                    <th className="px-4 py-2">Category</th>
                    <th className="px-4 py-2">Completed Date</th>
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {completedTasks.map((task) => (
                    <motion.tr
                      key={task.id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <td className="border px-4 py-2">{task.task}</td>
                      <td className="border px-4 py-2">{task.category}</td>
                      <td className="border px-4 py-2">{new Date(task.updated_at).toLocaleDateString()}</td>
                      <td className="border px-4 py-2">
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => reactivateTask(task.id)}
                          className="p-2 bg-blue-500 rounded mr-2"
                        >
                          Reactivate
                        </motion.button>
                        <motion.button
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          onClick={() => removeTask(task.id)}
                          className="p-2 bg-red-500 rounded"
                        >
                          Remove
                        </motion.button>
                      </td>
                    </motion.tr>
                  ))}
                </tbody>
              </table>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default TaskDashboard;