import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import AnimatedCursor from "react-animated-cursor"
import confetti from 'canvas-confetti';

const glitter = keyframes`
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
`;

const rainbow = keyframes`
  0% { color: red; }
  14% { color: orange; }
  28% { color: yellow; }
  42% { color: green; }
  57% { color: blue; }
  71% { color: indigo; }
  85% { color: violet; }
  100% { color: red; }
`;

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const GlobalStyle = createGlobalStyle`
  body {
    background-image: url('https://i.gifer.com/Wjd6.gif');
    background-repeat: repeat;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    cursor: none;
  }

  @media (max-width: 768px) {
    body {
      cursor: auto;
    }
  }
`;

const Container = styled.div`
  font-family: 'Comic Sans MS', cursive;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Title = styled.h1`
  font-size: 48px;
  color: #FF69B4;
  text-shadow: 2px 2px #FFC0CB, 4px 4px #FF1493;
  animation: ${rainbow} 5s linear infinite, ${float} 3s ease-in-out infinite;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const Section = styled.div`
  margin: 20px 0;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(255, 105, 180, 0.5);
  width: 100%;
  max-width: 600px;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Text = styled.p`
  font-size: 18px;
  color: #FF1493;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Button = styled(Link)`
  display: inline-block;
  padding: 10px 20px;
  font-size: 20px;
  color: white;
  background: linear-gradient(90deg, #FF69B4, #FF1493, #FF69B4);
  background-size: 400% 400%;
  border: none;
  border-radius: 25px;
  cursor: none;
  text-decoration: none;
  animation: ${glitter} 10s linear infinite;

  &:hover {
    animation: ${glitter} 2s linear infinite;
  }

  @media (max-width: 768px) {
    font-size: 18px;
    cursor: pointer;
  }
`;

const MarqueeText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  
  & > span {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 15s linear infinite;
  }

  @keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
  }
`;

const GlitterText = styled.span`
  background-image: url('https://media.giphy.com/media/l2Sqir5ZxfoS27EvS/giphy.gif');
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 26px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;

const HeartFrame = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  
  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 180px;
    height: 300px;
    border-radius: 150px 150px 0 0;
    background: #FF69B4;
  }
  
  &:before {
    left: 120px;
    transform: rotate(-45deg);
    transform-origin: 0 100%;
  }
  
  &:after {
    left: 0;
    transform: rotate(45deg);
    transform-origin: 100% 100%;
  }

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;

    &:before, &:after {
      width: 120px;
      height: 200px;
    }

    &:before {
      left: 80px;
    }
  }
`;

const HeartImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 50%;
  z-index: 1;

  @media (max-width: 768px) {
    width: 160px;
    height: 160px;
  }
`;

const FloatingHeart = styled.div`
  position: fixed;
  color: #FF69B4;
  font-size: 20px;
  animation: float 10s ease-in-out infinite;
  z-index: -1;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const CountdownTimer = styled.div`
  font-size: 24px;
  color: #FF1493;
  margin-top: 20px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const ConfettiButton = styled.button`
  background-color: #FF69B4;
  border: none;
  border-radius: 25px;
  color: white;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #FF1493;
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  text-align: left;

  li {
    margin-bottom: 10px;
  }
`;

const getRandomPosition = () => ({
  left: `${Math.random() * 100}vw`,
  top: `${Math.random() * 100}vh`,
  animationDuration: `${Math.random() * 5 + 5}s`,
});

const calculateTimeRemaining = () => {
  const difference = +new Date("2024-08-21") - +new Date();
  return {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60)
  };
};

const Countdown = () => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <CountdownTimer>
      <GlitterText>💖 Time until our next date 💖</GlitterText>
      <p>{timeRemaining.days}d {timeRemaining.hours}h {timeRemaining.minutes}m {timeRemaining.seconds}s</p>
    </CountdownTimer>
  );
};

const LandingPage = () => {
  const triggerConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  return (
    <>
      <GlobalStyle />
      <AnimatedCursor
        innerSize={8}
        outerSize={35}
        innerScale={1}
        outerScale={1.7}
        outerAlpha={0}
        hasBlendMode={true}
        innerStyle={{
          backgroundColor: '#FF69B4'
        }}
        outerStyle={{
          border: '3px solid #FF69B4'
        }}
        clickables={[
          'a',
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          'label[for]',
          'select',
          'textarea',
          'button',
          '.link'
        ]}
      />
      <Container>
        <Title>✨ 🌈💖 My Amazing Girlfriend Hanna 🌈💖 ✨</Title>
        
        <MarqueeText>
          <span>Welcome to my page dedicated to the love of my life, Hanna! 💖👩‍❤️‍👨💖 She's the most amazing person in the world!</span>
        </MarqueeText>
        
        <Section>
          <GlitterText>💖 Hanna's Pic 💖</GlitterText>
          <HeartFrame>
            <HeartImage src="https://i.imgur.com/m9FDTSD.jpeg" alt="Cute picture of Hanna" />
          </HeartFrame>
          <Text>Isn't she the cutest? 😍</Text>
          <ConfettiButton onClick={triggerConfetti}>Celebrate Hanna! 🎉</ConfettiButton>
        </Section>
        
        <Section>
          <GlitterText>💖 Reasons why I Love Hanna 💖</GlitterText>
          <Text>Hanna is the most amazing person I've ever met. She lights up my world every single day!</Text>
          <List>
            <li>💕 She's incredibly kind and caring</li>
            <li>🌟 Her smile brightens up any room</li>
            <li>😂 She has the best sense of humor</li>
            <li>🎨 She's very creative</li>
            <li>🎵 She twitches when she falls asleep</li>
            <li>🐾 She loves animals</li>
            <li>📚 She's always eager to learn new things</li>
            <li>🌺 She appreciates the little things in life</li>
            <li>🤗 She gives the best hugs ever</li>
            <li>👂 She's an amazing listener</li>
            <li>🎭 Her ability to make me laugh even when I'm sad</li>
            <li>🧘‍♀️ Her calming presence</li>
            <li>🎁 She's thoughtful and gives the best gifts</li>
            <li>🌱 Her passion for the environment</li>
            <li>🏋️‍♀️ She motivates me to be a better person</li>
            <li>🌙 She loves the moon</li>
            <li>🗺️ Her sense of adventure</li>
            <li>🧁 She bakes the most delicious pain au chocolats</li>
            <li>🌄 Waking up next to her is the best feeling</li>
            <li>☕ She makes me coffee in the morning</li>
            <li>🧣 She looks cute in my clothes</li>
            <li>🌿 She's a good plant mum</li>
            <li>🧘‍♀️ She keeps me grounded</li>
            <li>🎨 Her handwriting is beautiful</li>
            <li>🌸 She notices and appreciates small gestures</li>
            <li>🎭 She's supportive of my dreams and goals</li>
            <li>🌈 She brings color to my world</li>
            <li>💖 Simply put, she's my Hanna</li>
          </List>
        </Section>
        
        <Section>
          <GlitterText>💖 Hanna's Favorites 💖</GlitterText>
          <List>
            <li>Raspberry croissants</li>
            <li>Chai Lattes</li>
            <li>Being tied up</li>
            <li>Cups of coffee in the morning, but tea in the day</li>
            <li>Cats and Dogs</li>
            <li>Putting small amounts of poison into my coffee to keep me weak</li>
            <li>Noodles and Pizza</li>
            <li>Soy Candles</li>
            <li>Getting Choked out and spat on</li>
            <li>Spicy things</li>
            <li>Saying "fucking hell" when she jizzums</li>
            <li>Barry M and Superdrug cosmetics</li>
            <li>Crystals</li>
            <li>Getting little bracelets</li>
            <li>Gong Baths</li>
            <li>Vindaloo</li>
            <li>Silver stuff</li>
            <li>Being romanticised</li>
          </List>
        </Section>
      
        <Section>
          <Countdown />
        </Section>
        
        <Section>
          <Button to="/dashboard">Super cool planner 💘</Button>
        </Section>
        
        <MarqueeText>
          <span>Hanna, if you're reading this, I love you to the moon and back! 🌙💖 You're the best girlfriend in the whole wide world!</span>
        </MarqueeText>

        {[...Array(20)].map((_, i) => (
          <FloatingHeart key={i} style={getRandomPosition()}>💖</FloatingHeart>
        ))}
      </Container>
    </>
  );
};

export default LandingPage;